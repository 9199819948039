<template>
    <div>
        <div>欢迎进入首页</div>
        <div :key="index" class="item" v-for="(item,index) in routeList">
            <router-link :to="item">{{item}}</router-link>
        </div>
    </div>
</template>

<script>
import { local } from 'me-view'
export default {
  data() {
    return {
      routeList: []
    }
  },
  created() {
    this.routeList = { ...local.get('routeList') || [] }
  }
}
</script>
<style scoped>
.item {
  margin: 5px;
}
</style>
